





































import { Component, Vue, Watch } from "vue-property-decorator";

export default class OrganigramaMuni extends Vue {
  private selected = null;
  private expanded = ["Satisfied customers"];
  private win = window;
  private base = window.location;

  private simple: Array<any> = [
    {
      label: "Alcaldía",
      story: "Tomás Vodanovic",
      img: require("@/assets/Organigrama/icon-default.png"),
      handler: node => this.myclick(node),
      body: "story",
      children: [
        {
          label: "Consejo de la Sociedad Civil",
          img: require("@/assets/Organigrama/icon-default2.png"),
          handler: node => this.myclick(node),
          nombre: ""
        },
        {
          label: "Gabinete Alcaldía",
          nombre: "",
          img: require("@/assets/Organigrama/icon-default2.png")
        },
        {
          label: "CODEDUC",
          img: require("@/assets/Organigrama/icon-default2.png"),
          story: "Ignacio Cáceres",
          body: "story"
        },
        {
          label: "Concejo Municipal",
          img: require("@/assets/Organigrama/icon-default.png"),
          handler: node => this.myclick(node),
          nombre: "",
          children: [
            {
              label: "Administración Municipal",
              img: require("@/assets/Organigrama/icon-default.png"),
              story: "Jorge Córdova",
              body: "story",
              children: [
                {
                  label: "SMAPA",
                  story: "Yorka López",
                  body: "story",
                  img: require("@/assets/Organigrama/icon-default2.png")
                },
                {
                  label: "Control",
                  story: "Cristian Rubio (s)",
                  body: "story",
                  img: require("@/assets/Organigrama/icon-default2.png")
                },
                {
                  label: "Asesoría Jurídica",
                  story: "Juan Carlos Anabalón",
                  body: "story",
                  img: require("@/assets/Organigrama/icon-default.png"),
                  children: [
                    {
                      label: "Subdirección de Defensoría Ciudadana",
                      story: "Mauricio Estrada",
                      body: "story",
                      img: require("@/assets/Organigrama/icon-default2.png")
                    }
                  ]
                },
                {
                  label: "Juzgagos",
                  img: require("@/assets/Organigrama/icon-default.png"),
                  nombre: "",
                  children: [
                    {
                      label: "1° Juzgado",
                      story: "Carla Torres",
                      body: "story",
                      img: require("@/assets/Organigrama/icon-default2.png")
                    },
                    {
                      label: "2° Juzgado",
                      story: "Luis Rojas",
                      body: "story",
                      img: require("@/assets/Organigrama/icon-default2.png")
                    },
                    {
                      label: "3° Juzgado",
                      story: "Jacqueline Guajardo",
                      body: "story",
                      img: require("@/assets/Organigrama/icon-default2.png")
                    }
                  ]
                },
                {
                  label: "Secretaría Municipal",
                  story: "Claudia Sandoval",
                  body: "story",
                  img: require("@/assets/Organigrama/icon-default2.png")
                },
                {
                  label: "Secretaría Comunal de Planificación",
                  story: "Rocío Andrade",
                  body: "story",
                  img: require("@/assets/Organigrama/icon-default2.png")
                },
                {
                  label: "Seguridad Pública",
                  story: "Felipe Fernández",
                  body: "story",
                  img: require("@/assets/Organigrama/icon-default2.png")
                },
                {
                  label: "Obras Municipales",
                  story: "Germán Arce",
                  body: "story",
                  img: require("@/assets/Organigrama/icon-default2.png")
                },
                {
                  label: "Aseo, Ornato y Gestión Ambiental",
                  story: "Alex Gómez (s)",
                  body: "story",
                  img: require("@/assets/Organigrama/icon-default2.png")
                },
                {
                  label: "Tránsito y Transporte",
                  story: "Mauricio Llancamán",
                  body: "story",
                  img: require("@/assets/Organigrama/icon-default2.png")
                },
                {
                  label: "Operaciones",
                  story: "Antonio Carvajal (s)",
                  body: "story",
                  img: require("@/assets/Organigrama/icon-default2.png")
                },
                {
                  label: "Inspección",
                  story: "Martín Moraga",
                  body: "story",
                  img: require("@/assets/Organigrama/icon-default2.png")
                },
                {
                  label: "Salud",
                  story: "Ximena Barros",
                  body: "story",
                  img: require("@/assets/Organigrama/icon-default2.png")
                },
                {
                  label: "Desarrollo Comunitario",
                  story: "Pablo Flores",
                  body: "story",
                  img: require("@/assets/Organigrama/icon-default2.png")
                },
                {
                  label: "Administración y Finanzas",
                  story: "Ernesto Torres",
                  body: "story",
                  img: require("@/assets/Organigrama/icon-default.png"),
                  children: [
                    {
                      label: "Subdirección de RR.HH",
                      story: "Carolina Ojeda (s)",
                      body: "story",
                      img: require("@/assets/Organigrama/icon-default2.png")
                    }
                  ]
                },
                {
                  label: "Tecnología y Comunicaciones",
                  story: "Alfredo Baeza (s)",
                  body: "story",
                  img: require("@/assets/Organigrama/icon-default2.png")
                }
              ]
            }
          ]
        }
      ]
    }
  ];

  private mounted() {}

  private myclick(node: any) {
    if (node.label == "Alcaldía") {
      this.irLink("https://www.municipalidadmaipu.cl/alcalde");
    } else if (node.label == "Consejo de la Sociedad Civil") {
      this.irLink(
        "https://www.municipalidadmaipu.cl/consejo-de-la-sociedad-civil"
      );
    } else if (node.label == "Concejo Municipal") {
      this.irLink("https://www.municipalidadmaipu.cl/concejo/municipal");
    }
  }

  private irLink(link: any) {
    window.open(link, "_blank");
  }
}
