





















































































































































































































import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {}
})
export default class Organigrama extends Vue {
  private imgOrg = new Image();
  private canvas: any = null;
  private imgUrl: any = "";
  private horizontalPercentageInteres = 0;

  private mounted() {
    this.getRuntimeConfig();
    this.imgOrg.src = require("@/assets/Web/Organigrama.png");
    this.canvas = document.getElementById("myCanvas") as any;
  }

  private async getRuntimeConfig() {
    this.canvas = document.getElementById("myCanvas") as any;
    this.canvas.width = 1400;
    this.canvas.height = 873;
    const ctx = this.canvas.getContext("2d");
    if (this.canvas) {
      this.imgOrg.onload = () => {
        ctx.drawImage(this.imgOrg, 0, 0);
        this.imgUrl = this.canvas.toDataURL("image/png");
        this.scroll();
      };
    }
  }

  private scroll() {
    (this.$refs.scrollInteres as any).setScrollPosition(470, 320);
  }
}
