








import { Component, Vue } from "vue-property-decorator";
import OrganigramaMuni from "@/components/Municipalidad/OrganigramaMuni.vue";

@Component({
  components: {
    OrganigramaMuni
  }
})
export default class OrganigramaMuniView extends Vue {}
